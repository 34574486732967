import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { ProtocolDroidModule } from './services/protocol-droid/protocol-droid.module';
import { UiElementsModule } from './ui-elements/ui-elements.module';

import { FeedbackMessageComponent } from './components/feedback/feedback-message/feedback-message.component';
import { FeedbackSpinnerComponent } from './components/feedback/feedback-spinner/feedback-spinner.component';
import { ModalPasswordComponent } from './components/modals/modal-password/modal-password.component';
import { ModalPlanProposalComponent } from './components/modals/modal-plan-proposal/modal-plan-proposal.component';
import { ModalRemoveCardComponent } from './components/modals/modal-remove-card/modal-remove-card.component';

@NgModule({
  imports: [BsDropdownModule, CommonModule, FormsModule, PopoverModule, UiElementsModule, ProtocolDroidModule, ReactiveFormsModule, RouterModule],
  declarations: [FeedbackMessageComponent, FeedbackSpinnerComponent, ModalPasswordComponent, ModalRemoveCardComponent, ModalPlanProposalComponent],
  entryComponents: [FeedbackSpinnerComponent, FeedbackMessageComponent],
  exports: [BsDropdownModule, CommonModule, FormsModule, PopoverModule, UiElementsModule, ProtocolDroidModule, ReactiveFormsModule, RouterModule]
})
export class SharedModule {}
