import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
  AuthCredential,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  reauthenticateWithCredential,
  sendEmailVerification,
  unlink,
  updateEmail,
  UserCredential
} from 'firebase/auth';
import { get } from 'lodash';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { ProductMeta } from 'ideta-library/lib/common/bot';

import { environment } from '../../../../environments/environment';

import { FeedbackService } from '../feedback/feedback.service';
import { RollbarService } from '../rollbar/rollbar.service';

import { Auth, Credential, UserInfos } from './auth.types';

// ###A
export interface SessionState {
  isConnected: boolean;
  isSessionActive: boolean;
  isFacebookProvided: boolean;
}

// ###A
export interface LogoutOptions {
  bypassSessionEnd?: boolean;
  bypassRedirect?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private gapiAuth2: any;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private injector: Injector,
    private httpclient: HttpClient,
    private feedbackService: FeedbackService
  ) {
    this.getAuth()
      .pipe(first((auth: Auth) => !!(auth && auth.uid)))
      .subscribe((auth: Auth) => {
        const rollbar = this.injector.get(RollbarService);
        rollbar.configure({
          payload: {
            person: {
              id: auth.uid
            }
          }
        });
      });
  }

  getAuth(): Observable<Auth> {
    return this.afAuth.authState as Observable<Auth>;
  }

  getAuthAsync(): Promise<Auth> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState
        .pipe(
          catchError(error => {
            reject(error);
            return of(error);
          })
        )
        .subscribe(auth => resolve(auth));
    });
  }

  // Angular FireAuth Auth utils
  verifyPasswordCode(code: string): Promise<string> {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  applyActionCode(code: string): Promise<void> {
    return this.afAuth.applyActionCode(code);
  }

  loginWithCredentials({ email, password }: { email: string; password: string }) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  sendResetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email, {
      url: environment.domain
    });
  }

  // Angular FireAuth User utils
  getCurrentUser() {
    return getAuth().currentUser as Auth;
  }

  reauthenticateWithCredential(credential: AuthCredential): Promise<Credential> {
    return reauthenticateWithCredential(this.getCurrentUser(), credential);
  }

  updateEmail(email: string): Promise<void> {
    return updateEmail(this.getCurrentUser(), email);
  }

  sendVerifyEmail() {
    return sendEmailVerification(this.getCurrentUser(), {
      url: environment.domain
    });
  }

  // Other Utils

  signinWithGoogle(): Promise<UserCredential> {
    // Important ! This scope will guarantee the presence of the email address after login
    const scope = 'https://www.googleapis.com/auth/userinfo.email';
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    provider.addScope(scope);
    return this.afAuth
      .signInWithPopup(provider)
      .then(result => {
        if (get(result, 'additionalUserInfo.isNewUser')) this.sendGASignup('google');
        return result;
      })
      .catch((error: any) => {
        if (error.code !== 'auth/cancelled-popup-request') console.error(error);
        return null;
      });
  }

  signinWithFacebook(): Promise<UserCredential> {
    // Important ! This scope will guarantee the presence of the email address after login
    const scope = 'email';
    const provider = new FacebookAuthProvider();
    provider.addScope(scope);
    return this.afAuth
      .signInWithPopup(provider)
      .then(result => {
        if (get(result, 'additionalUserInfo.isNewUser')) this.sendGASignup('facebook');
        return result;
      })
      .catch((error: any) => {
        if (error.code !== 'auth/cancelled-popup-request') console.error(error);
        return null;
      });
  }

  createUserWithEmailAndPassword({ email, password }: { email: string; password: string }) {
    return this.httpclient
      .post(`${environment.backendUrl}/user/create`, {
        email,
        password
      })
      .toPromise()
      .then(() => {
        this.sendGASignup('email');
        return this.afAuth.signInWithEmailAndPassword(email, password);
      });
    // .then(() => {
    //   this.sendVerifyEmail();
    // });
  }

  getCredential(email: string, password: string): Promise<AuthCredential> {
    return new Promise<any>((resolve, reject) => {
      try {
        const creds = EmailAuthProvider.credential(email, password);
        resolve(creds);
      } catch (error) {
        reject(error);
      }
    });
  }

  async handleUserArrival(user: Auth, options: { longSession?: boolean; redir?: string } = {}): Promise<void> {
    if (user && !user.isAnonymous) {
      await this.feedbackService
        .showSpinner()
        .then(() => this.createSession(user, options.longSession))
        .then(() => {
          this.feedbackService.hideSpinner();
          if (!!options.redir) {
            try {
              const parsedRedir = new URL(options.redir);
              const validRedir =
                Object.keys(environment.products).find(key => environment.products[key] === parsedRedir.origin) ||
                window.location.origin === parsedRedir.origin; // handles intern redirections
              if (validRedir) window.location.href = options.redir;
            } catch {}
          }
          this.router.navigate(['/tools'], { state: { ignoreGuard: true } });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  logout(options: LogoutOptions): Promise<void> {
    return this.afAuth
      .signOut()
      .then(() => (!options.bypassSessionEnd ? this.endSession() : Promise.resolve()))
      .then(() => {
        if (!options.bypassRedirect) this.router.navigate(['/login'], { state: { ignoreGuard: true } });
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  getProviderData(providerId: 'password' | 'facebook.com'): Observable<UserInfos | undefined> {
    return this.getAuth().pipe(map((auth: Auth) => auth && auth.providerData.find((providerData: any) => providerData.providerId === providerId)));
  }

  grantOfflineAccess(): Promise<any> {
    if (this.gapiAuth2) {
      return this.gapiAuth2.grantOfflineAccess();
    }
    return Promise.reject('GAPI is not ready');
  }

  checkSession(): Observable<SessionState> {
    const res: SessionState = { isConnected: false, isSessionActive: false, isFacebookProvided: false };
    return this.getAuth().pipe(
      switchMap(auth => {
        res.isConnected = auth && !auth.isAnonymous;
        res.isFacebookProvided = res.isConnected && auth.providerData.length === 1 && auth.providerData[0].providerId === 'facebook.com';
        return environment.localLogin
          ? of({ access_token: res.isConnected ? 'granted' : null })
          : /* auth param in url is always true in Connect because :
          1 - We only use this endpoint to check the presence and validity of the session cookie.
          2 - in Connect's case, there is no "login with token" process so we do not need to generate a token 
         */
            this.httpclient.get<{ access_token: string }>(`${environment.backendUrl}/user/auth/check-session?auth=true`, {
              withCredentials: true
            });
      }),
      catchError(() => of({ access_token: null })),
      map(check => {
        res.isSessionActive = !!check.access_token;
        return res;
      })
    );
  }

  linkProviderToAccount(credential: AuthCredential): Promise<UserCredential> {
    return linkWithCredential(this.getCurrentUser(), credential);
  }

  unlinkProviderFromAccount(provider: string): Promise<Auth> {
    return unlink(this.getCurrentUser(), provider);
  }

  linkFbWithAuthAccount(product: ProductMeta = 'facebook'): Promise<UserCredential> {
    const provider = new FacebookAuthProvider();
    const scope = product === 'facebook' ? environment.facebook.scopes.join(',') : environment.instagram.scopes.join(',');
    provider.addScope(scope);

    this.unlinkProviderFromAccount('facebook.com').catch(error => error);

    return linkWithPopup(this.getCurrentUser(), provider).catch((error: any) => {
      if (error.code === 'auth/popup-closed-by-user' || error.code === 'auth/cancelled-popup-request') return Promise.reject(''); // muting these errors
      if (error.code === 'auth/provider-already-linked') {
        return this.unlinkProviderFromAccount('facebook.com').then(() => this.linkFbWithAuthAccount(product));
      } else {
        throw error;
      }
    });
  }

  endSession(): Promise<any> {
    if (environment.localLogin) return Promise.resolve();
    return lastValueFrom(this.httpclient.post(`${environment.backendUrl}/user/auth/end-session`, null, { withCredentials: true }));
  }

  private createSession(user: Auth, longSession: boolean = false): Promise<any> {
    if (environment.localLogin) return Promise.resolve();
    return user
      .getIdToken()
      .then(idToken =>
        lastValueFrom(
          this.httpclient.post(
            `${environment.backendUrl}/user/auth/create-session`,
            { idToken, longSession, id: user.uid },
            { withCredentials: true }
          )
        )
      );
  }

  private sendGASignup(origin: 'email' | 'facebook' | 'google'): void {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ event: 'signUp', origin });
  }
}
