import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonIconComponent implements OnInit {
  @Input() disabled = false;
  @Input() height = '1.25rem'; // 22px
  @Input() width?: string;
  @Input() size?: 'small' | 'medium' | 'large';
  @Input() square = true;
  @Input() active = false;
  @Input() color?: string;
  @Input() borderRadius: 'none' | 'less' | 'normal' | 'full' = 'normal'; // 0%, 15%, 30%, 50%
  @Input() noBackground = false;
  @Output() onClick: EventEmitter<any>;

  get sizeInRem() {
    const baseSize = 1; // 1rem
    switch (this.size) {
      case 'small':
        return baseSize * 0.95 + 'rem';
      case 'medium':
        return baseSize * 1.25 + 'rem';
      case 'large':
        return baseSize * 1.65 + 'rem';
      default:
        return null;
    }
  }

  get getClassSmall() {
    return this.size === 'small' || Number(this.height.replace('rem', '').replace('px', '')) <= 1.1;
  }

  get buttonStyle() {
    return {
      height: this.sizeInRem ? this.sizeInRem : this.height,
      width: this.sizeInRem ? (!this.square ? 'auto' : this.sizeInRem) : this.width ? this.width : 'auto',
      'font-size': this.sizeInRem ? this.sizeInRem : this.height,
      'line-height': this.sizeInRem ? this.sizeInRem : this.height,
      color: this.color ? this.color : environment.colors.gray8,
      fill: this.color ? this.color : environment.colors.gray8
    };
  }

  constructor() {
    this.onClick = new EventEmitter();
  }

  ngOnInit(): void {}
}
