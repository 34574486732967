import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ProtocolDroidModule } from '../services/protocol-droid/protocol-droid.module';

import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeErrorsComponent } from './components/badge-errors/badge-errors.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { ButtonRoundComponent } from './components/button-round/button-round.component';
import { CalloutComponent } from './components/callout/callout.component';
import { CaretComponent } from './components/caret/caret.component';
import { InlineMediaUploadComponent } from './components/inline-media-upload/inline-media-upload.component';
import { NavPanelsComponent } from './components/nav-panels/nav-panels.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { PlanCardComponent } from './components/plan-card/plan-card.component';
import { TinyBotSpinnerComponent } from './components/tiny-bot-spinner/tiny-bot-spinner.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { InnerSidenavComponent } from './inner-sidenav/inner-sidenav.component';

@NgModule({
  imports: [
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule.forRoot(),
    ProtocolDroidModule
  ],
  declarations: [
    TinyBotSpinnerComponent,
    ButtonIconComponent,
    NavPanelsComponent,
    NavTabsComponent,
    InlineMediaUploadComponent,
    AvatarComponent,
    InnerSidenavComponent,
    BadgeErrorsComponent,
    CaretComponent,
    ButtonRoundComponent,
    PlanCardComponent,
    ToggleComponent,
    CalloutComponent
  ],
  exports: [
    AccordionModule,
    BsDatepickerModule,
    RouterModule,
    TooltipModule,
    TinyBotSpinnerComponent,
    ButtonIconComponent,
    NavPanelsComponent,
    NavTabsComponent,
    InlineMediaUploadComponent,
    AvatarComponent,
    BadgeErrorsComponent,
    InnerSidenavComponent,
    CaretComponent,
    ButtonRoundComponent,
    PlanCardComponent,
    ToggleComponent,
    CalloutComponent
  ]
})
export class UiElementsModule {}
