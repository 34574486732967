import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserSessionService } from '../../session/user-session.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AntiAuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private _user: UserSessionService) {}

  canActivate(): Observable<boolean> {
    // explicit order coming from some internal navigations
    if (this.router.getCurrentNavigation()?.extras?.state?.ignoreGuard) {
      return of(true);
    }

    return this.authService.checkSession().pipe(
      switchMap(async res => {
        // ###L - redirect Facebbok provided users to the migration page
        if (res.isFacebookProvided) {
          this.router.navigate(['/account-migration'], { state: { ignoreGuard: true } });
          return false;
        }

        // Valid connection
        if (res.isConnected && res.isSessionActive) {
          this.router.navigate(['/'], { state: { ignoreGuard: true } });
          return false;
        }

        // Invalid connection cases
        if (res.isConnected) await this._user.disconnect({ bypassSessionEnd: !res.isSessionActive, bypassRedirect: true });
        else if (res.isSessionActive) await this.authService.endSession();
        return true;
      })
    );
  }
}
