import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { get, isEqual } from 'lodash';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { APP_PLANS, Licence } from 'ideta-library/lib/common/pricing';
import { User } from 'ideta-library/lib/common/user';

import { AuthService, LogoutOptions } from '../../services/auth/auth.service';
import { FeedbackService } from '../feedback/feedback.service';
import { ProtocolDroidService } from '../protocol-droid/services/protocol-droid.service';
import { RequestsService } from '../requests/requests.service';
import { CoreSessionService } from './core-session.service';

import { Auth } from '../auth/auth.types';
import { SessionModel } from './session.model';

export interface MetaConnectionStatus {
  linkedTo: string[];
  onlyProvider?: boolean;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserSessionService implements SessionModel, OnDestroy {
  public fireMeta: Auth;
  public hasNotifications: boolean;
  public hasPendings: boolean;
  private referrer: string;
  private _licences$: BehaviorSubject<Licence[]>;
  private _subject$: BehaviorSubject<User>;
  private userSub: Subscription;
  private licencesSub: Subscription;
  private authSub: Subscription;

  get subject$() {
    return this._subject$.pipe(filter(value => value && !!value.id));
  }

  private get user(): Partial<User> {
    return this._subject$.value || {};
  }

  get distinctSubject$() {
    return this.subject$.pipe(
      filter(value => value && !!value.id),
      distinctUntilChanged((prev, curr) => prev.id === curr.id)
    );
  }

  get value(): Partial<User> {
    return this._subject$.value || {};
  }

  get id() {
    return this.user.id;
  }

  get email() {
    return this.user.email;
  }

  get lang() {
    return this.user.lang;
  }

  get settings() {
    return this.user.settings;
  }

  get isSuperAdmin() {
    return this.user.status === 'super_admin';
  }

  get isAdmin() {
    return this.user.status === 'admin';
  }

  get customer() {
    return this.user.customer as any;
  }

  get customerName() {
    return this.customer.name;
  }

  get customerId() {
    return this.user.customer && this.user.customer.id;
  }

  get customerCardId() {
    return get(this.user, 'customer.card.id');
  }

  get customerCardExpMonth() {
    return get(this.user, 'customer.card.exp_month');
  }

  get customerCardExpYear() {
    return get(this.user, 'customer.card.exp_year');
  }

  get customerLast4() {
    return get(this.user, 'customer.card.last4');
  }

  get gocardlessId() {
    return get(this.user, 'gocardless.id');
  }

  get facebook() {
    return this.user.facebook;
  }

  get instagram() {
    return this.user.instagram;
  }

  get trialStart() {
    return this.user.trialStart;
  }

  get exists(): boolean {
    return !!this._subject$.value;
  }

  get licences$(): Observable<Licence[]> {
    return this._licences$.pipe(filter(licences => !!licences));
  }

  get hasRightInBot(): User['hasRightInBot'] {
    return (this.user.hasRightInBot && this.user.hasRightInBot.bind(this.user)) || (() => false);
  }

  get hasRightInWorkspace(): User['hasRightInWorkspace'] {
    return (this.user.hasRightInWorkspace && this.user.hasRightInWorkspace.bind(this.user)) || (() => false);
  }

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private router: Router,
    private protocolDroidService: ProtocolDroidService,
    private requestsService: RequestsService,
    private _session: CoreSessionService,
    private feedbackService: FeedbackService
  ) {
    this._licences$ = new BehaviorSubject(null);
    this._subject$ = new BehaviorSubject<any>(null);
    this.authSub = this.authService
      .getAuth()
      .pipe(
        tap(user => (this.fireMeta = user)),
        map(user => user && user.uid),
        distinctUntilChanged()
      )
      .subscribe(userId => {
        if (userId) this.startSession(userId);
      });
  }

  startSession(userId: string) {
    // ###M
    // console.log('init _user', userId);
    this.userSub = this.getUser(userId)
      .pipe(
        catchError(error => {
          // If a permission_denied have been triggered, it means that the user
          // have lost his connection to his account (logout from another tab, network error)
          if (error && error.code === 'PERMISSION_DENIED') this.disconnect({ bypassSessionEnd: true });
          return of(null);
        }),
        filter(user => !!user),
        tap(user => {
          this.hasNotifications = !!Object.keys(user!.notifications).length;
          this.hasPendings = !!Object.keys(user!.pendingLicences).length;
          this._subject$.next(user!);
          this.protocolDroidService.setLang(this.user.lang || get(navigator, 'language') || get(navigator, 'userLanguage'));
        }),
        switchMap(() => this._session.routerEvent$)
      )
      .subscribe(event => {
        // Auto redirect to user licences if has notifications for appsumo plans
        if (
          event.location === 'tools' &&
          this.hasNotifications &&
          !!Object.keys(this.value.notifications).find(notifKey => APP_PLANS[this.value?.notifications?.[notifKey].to]?.collection === 'appsumo')
        ) {
          // SetTimeout necessary to avoid Navigation Throttling issues
          setTimeout(() => this.navigateToSettings('licences'), 100);
        }
      });
  }

  endSession() {
    // ###M
    // console.log('reset _user');
    this.userSub?.unsubscribe();
    this.licencesSub?.unsubscribe();
    this._subject$.next(null);
    this._licences$.next(null);
    this.fireMeta = this.hasNotifications = this.hasPendings = null;
    this.referrer = '';
  }

  ngOnDestroy() {
    this.endSession();
    if (this.authSub) this.authSub.unsubscribe();
  }

  disconnect(options: LogoutOptions = {}): Promise<void> {
    this.feedbackService.showSpinner();
    this._session.endSession();
    this.endSession();

    // Waiting for all session services to stop;
    return new Promise<void>(resolve => {
      setTimeout(
        () =>
          this.authService.logout(options).then(() => {
            this.feedbackService.hideSpinner();
            resolve();
          }),
        50
      );
    });
  }

  getMetaStatus(): Observable<MetaConnectionStatus> {
    return this.subject$.pipe(
      switchMap(() => this.authService.getAuth()),
      filter(auth => !!auth),
      map(auth => {
        const fbProvider = auth.providerData.find(data => data.providerId === 'facebook.com');
        const linkList: string[] = [];
        // Keep in this order to display the meta infos beside the connection toggle
        if (!!get(this.value, 'facebook.accessToken')) linkList.push('facebook');
        if (!!get(this.value, 'instagram.accessToken')) linkList.push('instagram');
        if (!!get(this.value, 'whatsapp.accessToken')) linkList.push('whatsapp');
        return {
          linkedTo: linkList,
          onlyProvider: !!fbProvider && auth.providerData.length === 1
        };
      })
    );
  }

  fetchLicences(bypassIfInit: boolean): void {
    if (bypassIfInit && !!this._licences$.value) return;
    this.licencesSub?.unsubscribe();
    this.licencesSub = this.subject$
      .pipe(
        distinctUntilChanged((prev, current) => isEqual(prev.licences, current.licences)),
        switchMap(() => from(this.getLicences())),
        catchError(() => of([]))
      )
      .subscribe(licences => {
        // ###M
        // console.log('init _user licences');
        this._licences$.next(licences);
      });
  }

  navigateToSettings(tab: string = 'general'): void {
    if (!this.referrer) this.referrer = window.location.pathname + window.location.search;
    this.router.navigate(['user'], { queryParams: { tab } });
  }

  navigateToReferrer(): void {
    const ref = this.referrer;
    this.referrer = '';
    this.router.navigateByUrl(ref);
  }

  // Below functions has to be in this service to avoid circular dependencies
  private getUser(userId: string): Observable<User> {
    return this.db
      .object<User>('/users/' + userId)
      .valueChanges()
      .pipe(map(user => new User({ ...user, id: userId })));
  }

  private getLicences(): Promise<Licence[]> {
    return this.requestsService.get<Licence[]>(`user/${this.id}/licences`).then(list => list?.map(licence => new Licence(licence)) || []);
  }
}
