import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { get } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Channel } from 'ideta-library/lib/common/bot';

export type AppPage = 'login' | 'signup' | 'forgot-password' | 'change-password' | 'oauth-callback' | 'user' | 'tools';

export interface RouterEvent {
  location: AppPage;
  botId?: string;
  channel?: Channel;
  workspace?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CoreSessionService implements OnDestroy {
  private _routerEvent$: BehaviorSubject<RouterEvent>;
  private _previousRouterEvent$: BehaviorSubject<RouterEvent>;
  private routerSub: Subscription;

  get routerEvent$() {
    return this._routerEvent$.pipe(filter(event => !!event));
  }

  get location(): AppPage {
    return get(this._routerEvent$, 'value.location', null);
  }

  constructor(private router: Router) {
    this._routerEvent$ = new BehaviorSubject(null);
    this._previousRouterEvent$ = new BehaviorSubject(null);
    this.routerSub = this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe(event => {
      // For pages in the Idechat Module, there are 3 levels of nodes to browse (ROOT(AppComponent)/Idechat/{target})
      // For pages in the App Module, there is 1 level to browse (ROOT(AppComponent)/{target}))
      const routerNode: ActivatedRouteSnapshot = get(event, 'state.root.firstChild.firstChild.firstChild', get(event, 'state.root.firstChild', {}));

      const routerEvent: RouterEvent = {
        location: get(routerNode, 'url[0].path') as AppPage,
        botId: get(routerNode, 'params.botId'),
        channel: get(routerNode, 'queryParams.channel'),
        workspace: get(routerNode, 'params.workspaceId')
      };
      this._previousRouterEvent$.next(this._routerEvent$.value);
      this._routerEvent$.next(routerEvent);
    });
  }

  endSession() {
    this._routerEvent$.next({ location: 'login' });
  }

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  public emit(state: RouterEvent) {
    this._routerEvent$.next(state);
  }
}
