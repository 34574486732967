import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserCredential } from 'firebase/auth';
import { get } from 'lodash';
import { Subscription } from 'rxjs';

import { AuthService } from '../shared/services/auth/auth.service';
import { FeedbackService } from '../shared/services/feedback/feedback.service';
import { ProtocolDroidService } from '../shared/services/protocol-droid/services/protocol-droid.service';
import { UserSessionService } from '../shared/services/session/user-session.service';
import { UserService } from '../shared/services/user/user.service';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, OnDestroy {
  state: 'success';
  isLoading: boolean;
  isConnected: boolean;
  form: FormGroup;
  error: string;
  providerId: string;

  private firebaseEmail: string;
  private credentials: UserCredential;
  private subscriptions: Subscription[];

  constructor(
    public protocolDroidService: ProtocolDroidService,
    private feedbackService: FeedbackService,
    private authService: AuthService,
    private userService: UserService,
    private _user: UserSessionService
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, Validators.email),
      password: new FormControl(null, Validators.required),
      confirm: new FormControl(null, Validators.required)
    });
    this.isLoading = true;
    this.subscriptions = [];
  }

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  get confirm(): FormControl {
    return this.form.get('confirm') as FormControl;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.getAuth().subscribe(auth => {
        this.isConnected = !!auth;
        this.providerId = auth?.providerData[0].providerId;
        this.isLoading = false;
        if (!!auth && (!!auth.email || !!auth.providerData[0].email)) {
          this.email.patchValue(auth.email || auth.providerData[0].email);
          this.email.disable();
          this.firebaseEmail = this.email.value;
        }
      }),
      this.form.valueChanges.subscribe(() => {
        if (this.password.dirty && this.confirm.dirty) this.checkPasswords();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async facebookAuth(): Promise<void> {
    this.credentials = await this.authService.signinWithFacebook();
  }

  async googleAuth(): Promise<void> {
    this.credentials = await this.authService.signinWithGoogle();
  }

  executeProviderMigration(): void {
    if (this.form.invalid || !this.checkPasswords() || !this.isConnected) return;

    this.state = this.error = null;

    this.feedbackService
      .showSpinner()
      .then(() =>
        this.userService.executeProviderMigration(this.firebaseEmail || this.email.value, this.password.value, this.credentials, this.providerId)
      )
      .then(() => {
        this.state = 'success';
      })
      .catch(error => {
        if (get(error, 'error.code') === '40.3.2' && this.email.disabled) {
          this.firebaseEmail = null;
          this.email.enable();
        }
        this.error = this.protocolDroidService.translate('errors.' + get(error, 'error.code'), '', {
          email: this.firebaseEmail || this.email.value
        });
      })
      .finally(() => this.feedbackService.hideSpinner());
  }

  logout(): void {
    this.feedbackService
      .showMessage({
        message: 'idechat.logout-confirm',
        title: 'idechat.logout-confirm-title',
        type: 'confirm',
        decoration: 'warning',
        messageParams: {
          length: 1
        }
      })
      .then((isConfirmed: boolean) => {
        if (isConfirmed) {
          this._user.disconnect();
        }
      });
  }

  private checkPasswords(): boolean {
    if (this.password.value !== this.confirm.value) {
      this.error = this.protocolDroidService.translate('misc.errors.passwords-matching');
      this.form.setErrors({ notMatchingError: true });
      return false;
    } else {
      this.error = null;
      this.form.setErrors(null);
      return true;
    }
  }
}
