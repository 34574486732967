<div class="login-container ideta-scrollbar">
  <div class="login-card ideta-scrollbar">
    <img class="login-card-logo" src="/assets/img/ideta_logo.png" alt="Ideta" />
    <h1 appProtocolDroid="login.title">
      Welcome back ! <br />
      Break the code barrier
    </h1>
    <form class="login-card-form" (keydown.enter)="loginWithCredentials($event, email.value, password.value)">
      <input
        class="form-control email m-2"
        type="email"
        name="Email"
        appProtocolDroidAttribute="placeholder"
        appProtocolDroid="login.email_placeholder"
        [(ngModel)]="emailInput"
        #email
      />
      <div class="password-bloc">
        <input
          class="form-control password"
          type="password"
          name="Password"
          appProtocolDroidAttribute="placeholder"
          appProtocolDroid="login.password_placeholder"
          #password
        />
        <i class="fas" [class.fa-eye]="!isPasswordHide" [class.fa-eye-slash]="isPasswordHide" (click)="setTypeInputPassword()"></i>
      </div>

      <div class="d-flex align-items-center m-2">
        <input type="checkbox" id="stay-active" [formControl]="longSession" /><label class="ml-3 mb-0" appProtocolDroid="login.stay-active">
          Garder la session active</label
        >
      </div>
      <span class="login-card-form-error" *ngIf="handleSignInError()">{{ signInError }}</span>
    </form>
    <div class="login-card-info text-nowrap">
      <a [routerLink]="['/forgot-password']"><span appProtocolDroid="login.forgot-password">Forgot password ?</span></a>
    </div>
    <button
      type="submit"
      class="btn btn-md btn-primary login-card-btn login-card-form-btn"
      (click)="loginWithCredentials($event, email.value, password.value)"
      appProtocolDroid="login.btn-login"
    >
      log in
      <i class="fas fa-sign-in-alt"></i>
    </button>
    <span class="login-card-info" appProtocolDroid="login.separator">Or</span>
    <button class="btn login-card-btn login-card-btn-google" (click)="googleAuth()">
      <i class="fab fa-google"></i>
      <span appProtocolDroid="login.btn-google">Sign in with google</span>
    </button>
    <!-- <div class="login-card-info text-nowrap mt-2">
      <a [routerLink]="['/account-migration']" appProtocolDroid="login.fb-provided">I signed up with Facebook</a>
    </div> -->
    <div class="login-card-info text-nowrap mt-2">
      <div appProtocolDroid="login.dont-have">Don't have an account ?</div>
      <div>
        <a [routerLink]="['/signup']" [state]="{ ignoreGuard: true }" appProtocolDroid="login.sign-up">Sign up</a>
      </div>
    </div>
  </div>
</div>
