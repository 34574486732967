<div class="page-container">
  <!-- Log out -->
  <app-button-icon
    *ngIf="isConnected"
    class="option-right-item ml-auto"
    id="header-button-logout"
    size="large"
    (onClick)="logout()"
    placement="bottom"
    container="body"
    [tooltip]="protocolDroidService.translate('shared.ui-elements.components.sidenav-menu.logout')"
  >
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640" height="640" viewBox="0 0 640 640">
      <path
        d="M319.965 640c-85.002 0-164.991-32.946-225.364-92.854-60.672-60.206-94.269-140.436-94.598-225.91-0.331-85.474 32.646-165.961 92.851-226.634s140.436-94.269 225.91-94.599c0.422-0.001 0.85-0.003 1.271-0.003 85.004 0 164.991 32.945 225.363 92.854 60.674 60.206 94.269 140.436 94.599 225.91s-32.646 165.96-92.851 226.634c-60.206 60.673-140.436 94.269-225.91 94.599-0.423 0.001-0.849 0.003-1.271 0.003zM320.031 37.542c-0.378 0-0.746 0.001-1.123 0.002-75.446 0.291-146.264 29.946-199.406 83.501-53.142 53.556-82.249 124.599-81.958 200.046 0.291 75.445 29.945 146.262 83.501 199.406 53.286 52.876 123.9 81.956 198.924 81.96 0.378 0 0.743-0.001 1.121-0.002 75.446-0.291 146.264-29.946 199.406-83.502 53.144-53.554 82.251-124.598 81.957-200.044-0.291-75.446-29.945-146.262-83.501-199.406-53.286-52.877-123.899-81.96-198.923-81.96z"
      ></path>
      <path
        d="M319.88 483.806c-41.531 0.001-80.845-15.604-111.176-44.235-31.493-29.728-49.524-69.94-50.773-113.229-1.151-39.926 12.39-78.71 38.131-109.209 6.688-7.922 18.529-8.926 26.453-2.237 7.922 6.686 8.924 18.529 2.237 26.452-19.775 23.43-30.178 53.23-29.294 83.913 0.96 33.266 14.816 64.167 39.016 87.012 24.201 22.846 55.881 34.906 89.114 33.938 68.672-1.98 122.93-59.459 120.95-128.132-0.835-28.959-11.804-56.868-30.887-78.588-6.842-7.789-6.076-19.648 1.712-26.491s19.646-6.076 26.491 1.712c24.844 28.276 39.124 64.601 40.212 102.283 2.577 89.366-68.032 164.167-157.395 166.742-1.597 0.048-3.2 0.070-4.792 0.070z"
      ></path>
      <path
        d="M319.708 265.321c-10.333 0-18.73-8.357-18.77-18.699l-0.414-107.155c-0.040-10.367 8.332-18.803 18.699-18.844h0.074c10.334 0 18.73 8.357 18.77 18.699l0.414 107.155c0.040 10.367-8.332 18.803-18.699 18.844-0.025 0-0.049 0-0.074 0z"
      ></path>
    </svg>
  </app-button-icon>
  <img class="login-card-logo" src="/assets/img/ideta_logo.png" alt="Ideta" />
  <h1 appProtocolDroid="account.title">Account migration</h1>
  <span *ngIf="state !== 'success' && providerId === 'facebook.com'" class="desc" appProtocolDroid="account.desc">Explanations...</span>
  <app-tiny-bot-spinner *ngIf="isLoading"></app-tiny-bot-spinner>

  <form
    [formGroup]="form"
    class="change-password-form-container"
    *ngIf="!isLoading && state !== 'success' && isConnected"
    (ngSubmit)="executeProviderMigration()"
  >
    <div class="d-flex flex-column align-items-center">
      <label for="email" class="px-2 form-label" appProtocolDroid="account.email">Email</label>
      <input class="form-control m-2" type="email" id="email" name="email" formControlName="email" placeholder="Email..." />
      <label for="password" class="px-2 mt-2 form-label" appProtocolDroid="idechat.new-password">New Password</label>
      <input class="form-control m-2" type="password" id="password" name="password" formControlName="password" placeholder="Password..." />
      <label for="confirm" class="px-2 mt-2 form-label" appProtocolDroid="idechat.retype-new-password">Re-type new Password</label>
      <input class="form-control m-2" type="password" id="confirm" name="confirm" formControlName="confirm" placeholder="Password..." />
      <span class="m-2 text-danger error-message" *ngIf="!!error" [innerHTML]="error"></span>
      <button type="submit" [disabled]="form.invalid" class="mt-3 btn btn-md btn-primary change-password-btn" appProtocolDroid="account.submit">
        Submit new informations
      </button>
    </div>
  </form>
  <div class="change-password-form-container wide" *ngIf="!isLoading && state !== 'success' && !isConnected">
    <button type="button" class="btn login-card-btn login-card-btn-google" (click)="googleAuth()">
      <i class="fab fa-google"></i>
      <span appProtocolDroid="login.btn-google">Sign in with google</span>
    </button>
    <button type="button" class="btn login-card-btn login-card-btn-facebook" (click)="facebookAuth()">
      <i class="fab fa-facebook"></i>
      <span appProtocolDroid="login.btn-facebook">Sign in with facebook</span>
    </button>
  </div>

  <div class="info-text" *ngIf="state === 'success'">
    <i class="text-success fas fa-check"></i>
    <span class="sucess-text" appProtocolDroid="account.success" [appProtocolDroidParams]="{ email: email.value }">
      Congratulations, your account is now migrated to an email/password account ! From now on, you will be able to login by using your email [email]
      and the password that you provided.<br /><br />Nothing changed for all your projects, workspaces and integrations, everything is at its place !
    </span>
    <br />
    <a [routerLink]="['/login']" appProtocolDroid="account.success-link">Refresh my session</a>
  </div>
</div>
