import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AntiAuthGuardService } from '../app/shared/services/auth/anti-auth-guard/anti-auth-guard.service';
import { OauthProvidedGuardService } from './shared/services/auth/oauth-provided-guard/oauth-provided-guard.service';

import { AccountFormComponent } from './account-form/account-form.component';
import { AuthHandlerComponent } from './auth-handler/auth-handler.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignupComponent } from './signup/signup.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./idechat/idechat.module').then(m => m.IdechatModule)
  },
  {
    path: 'login',
    canActivate: [AntiAuthGuardService],
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'signup',
    canActivate: [AntiAuthGuardService],
    component: SignupComponent,
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    canActivate: [AntiAuthGuardService],
    component: ForgotPasswordComponent
  },
  {
    path: 'auth-handler',
    component: AuthHandlerComponent,
    pathMatch: 'full'
  },
  {
    path: 'account-migration',
    canActivate: [OauthProvidedGuardService],
    component: AccountFormComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
