import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../shared/services/auth/auth.service';
import { FeedbackService } from '../shared/services/feedback/feedback.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  emailInput: string;
  forgotPasswordError: string;
  status: 'pending' | 'success';
  private subscriptions: Subscription[];

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private feedbackService: FeedbackService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.emailInput = params.get('email');
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  resetPassword(): any {
    if (!this.emailInput) {
      return (this.forgotPasswordError = 'Type in your email first');
    }

    this.forgotPasswordError = null;
    this.status = 'pending';

    // Note about this chain :
    // the catch method at the center is here to display errors
    // only when the email is badly formatted.
    // When the user is not found, we simulate a success for security reasons
    this.authService
      .sendResetPassword(this.emailInput)
      .catch(error => {
        if (error.code === 'auth/invalid-email') throw error;
      })
      .then(() => {
        this.status = 'success';
        return this.feedbackService.showMessage({
          message: 'idechat.forgot-password-link'
        });
      })
      .then(() => this.router.navigate(['/login']))
      .catch(error => {
        this.forgotPasswordError = error.message;
        this.status = null;
      });
  }
}
